<template>
  <div>
    <van-nav-bar
      :title="chexin.carBrand+ '-' +chexin.carModel+ ' '+ chexin.tires"
      left-arrow
      :fixed="true"
      :z-index="999"
      @click-left="back"
    >
      <template #right>
        <img class="qiehuan" @click.stop="qiehuans" src="../../assets/img/qiehuan.png" alt />
      </template>
    </van-nav-bar>
    <!--<van-nav-bar title="秒杀列表" left-arrow :fixed="true" :z-index="999" @click-left="back" v-else>
      <template #right></template>
    </van-nav-bar>-->
    <div style="padding-top:46px;">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="10"
      >
        <div class="mian" @click="xiangqing(item.id)" v-for="(item,idx) in list" :key="idx">
          <div class="left mian_a">
            <img class="order_img" :src="item.coverImg1" alt />
          </div>
          <div class="left mian_b">
            <div class="shuo_a">{{item.partName}}</div>
            <div class="shuo_b">
              <span class="ciri">次日达</span>
              {{item.produceExplain}}
            </div>

            <div class="shuo_c">
              <span>{{item.title1}}</span>
              <span>{{item.title2}}</span>
            </div>
            <div class="jiage">
              <span class="danwei">￥</span>
              <span class="mone">{{item.activityPrice}}</span>
              <span class="qiangou">抢购价</span>
            </div>
          </div>
        </div>
      </van-list>
       <van-empty v-if="list.length < 1" description="暂无该车型数据~" />
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [],
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    xiangqing(id) {
      this.$router.push({
        path: "list_details",
        query: {
          id: id,
          Aswitch: 1
        }
      });
    },
    qiehuans() {
      this.$router.push({
        path: "specification",
        query: {
          isok: "true"
        }
      });
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      const data = {
        carModel: this.chexin.carBrand,
        // carModel: '宝马',
        classify: "轮胎",
        gid: this.shangjia.id,
        tires:this.chexin.tires,
        page: this.page,
        size: this.size
      };
      userModel
        .list(data)
        .then(e => {
          loading.clear();
          let rows = e.data;
          this.loading = false;
          this.total = e.pageInfo.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      this.page++;
      this.onLoad();
    },
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.qiehuan {
  width: 13px;
  height: 13px;
  vertical-align: middle;
  margin-right: 4px;
}
.order_img {
  height: 85px;
  width: 85px;
}
.mian {
  padding: 10px 10px;
  overflow: hidden;
}
.mian_a {
  width: 85px;
}
.mian_b {
  font-size: 14px;
  margin-left: 10px;
  width: 255px;
}
.ciri {
  display: inline-block;
  background: #deeff4;
  color: #55bae2;
  line-height: 12px;
  padding: 0 1px;
  font-size: 9px;
  border-radius: 3px;
  border: 0.5px solid #55bae2;
}
.shuo_a {
  font-weight: bold;
  line-height: 20px;
}
.shuo_b {
  font-size: 10px;
  color: #666;
  line-height: 16px;
}
.shuo_c {
  font-size: 10px;
}
.shuo_c span {
  display: inline-block;
  color: #a50e43;
  padding: 0 1px;
  line-height: 14px;
  background: #f1dadc;
}
.jiage {
  color: #a50e43;
  font-size: 16px;
  line-height: 16px;
  margin-top: 5px;
  border-bottom: 1px solid #f5f5f5;

  padding-bottom: 10px;
}
.mone {
  font-weight: bold;
}
.danwei {
  font-size: 10px;
  vertical-align: text-bottom;
}
.qiangou {
  font-size: 8px;
  border: 0.5px solid #a50e43;
  vertical-align: text-bottom;
}
.shuo_c span:nth-of-type(n + 2) {
  margin-left: 5px;
}
</style>